import intlTelInput from "intl-tel-input";
import utils from "intl-tel-input/build/js/utils";

import { Controller } from "stimulus";

export default class extends Controller {
  // phoneHidden represents phone value in db
  // phoneInput is intl-tel-input
  static targets = ["phoneInput", "phoneHidden", "validMsg", "errorMsg"];

  connect() {
    this.iti = intlTelInput(this.phoneInputTarget, {
      preferredCountries: ["us", "gb", "in", "au", "ca", "fr"],
      nationalMode: true,
      utilsScript: utils
    });
    this.errorMap = [
      "Invalid number",
      "Invalid country code",
      "Too short",
      "Too long",
      "Invalid number"
    ];
    this.phoneInputTarget.addEventListener(
      "countrychange",
      this.countryChange.bind(this)
    );
  }

  blur(e) {
    this.reset();
    if (this.phoneInputTarget.value.trim()) {
      if (this.iti.isValidNumber()) {
        this.errorMsgTarget.classList.add("hidden");
        this.validMsgTarget.classList.remove("hidden");
        const intlNumber = this.iti.getNumber();
        this.phoneHiddenTarget.value = intlNumber;
      } else {
        this.validMsgTarget.classList.add("hidden");
        var errorCode = this.iti.getValidationError();
        this.errorMsgTarget.innerHTML = this.errorMap[errorCode];
        this.errorMsgTarget.classList.remove("hidden");
      }
    }
  }

  change(e) {
    this.reset();
  }

  keyup(e) {
    this.reset();
  }

  reset() {
    this.errorMsgTarget.innerHTML = "";
    this.errorMsgTarget.classList.add("hidden");
    this.validMsgTarget.classList.add("hidden");
  }

  countryChange(e) {
    this.reset();
  }
}
