//discourse.stimulusjs.org/t/wait-for-alert-confirmation-to-run-stimulus-code

import { Controller } from "stimulus";

export default class extends Controller {
  onBeforeImageDelete(e) {
    this.elementToDelete = e.currentTarget;
  }
  onImageDelete(e) {
    if (this.elementToDelete) {
      this.elementToDelete.parentNode.remove();
      this.elementToDelete = null;
    }
  }

  onBeforeDocumentDelete(e) {
    //console.log("do stuff before ajax call");
    this.elementToDelete = e.currentTarget;
    //console.log(e.currentTarget);
    // e.preventDefault() stops ajax
  }

  onDocumentDelete(e) {
    const [data, status, xhr] = e.detail;
    //console.log("do stuff on ajax call success");
    if (this.elementToDelete) {
      //console.log(this.elementToDelete.parentNode);
      this.elementToDelete.parentNode.remove();
      this.elementToDelete = null;
    }
  }
}
