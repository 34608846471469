import { Controller } from "stimulus";

//https://boringrails.com/articles/hovercards-stimulus/

export default class extends Controller {
  static targets = ["text"];

  mouseOver() {
    this.textTarget.classList.add("tooltip-in");
  }

  mouseOut() {
    this.textTarget.classList.remove("tooltip-in");
  }

  mouseOverWithIndex(e) {
    this.index = Number(e.target.dataset.index);
    this.textTargets.forEach((element, index) => {
      if (index === this.index) {
        element.classList.add("tooltip-in");
      }
    });
  }

  mouseOutWithIndex(e) {
    this.textTargets.forEach((element, index) => {
      if (index === this.index) {
        element.classList.remove("tooltip-in");
      }
    });
  }
}
