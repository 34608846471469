import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["kMenu", "openIcon", "closeIcon", "mobileMenu"];

  //https://gomakethings.com/how-to-add-transition-animations-to-vanilla-javascript-show-and-hide-methods/

  toggleKMenu(event) {
    event.preventDefault();
    if (this.kMenuTarget.classList.contains("is-visible")) {
      this.hideKMenu();
      return;
    }
    this.showKMenu();
  }

  showKMenu() {
    const height = this.getkMenuHeight();
    this.kMenuTarget.classList.add("is-visible");
    this.kMenuTarget.style.height = height;

    window.setTimeout(() => {
      this.kMenuTarget.style.height = "";
    }, 350);
  }

  hideKMenu() {
    this.kMenuTarget.style.height = this.kMenuTarget.scrollHeight + "px";

    window.setTimeout(() => {
      this.kMenuTarget.style.height = 0;
    }, 1);

    window.setTimeout(() => {
      this.kMenuTarget.classList.remove("is-visible");
    }, 350);
  }

  getkMenuHeight() {
    this.kMenuTarget.style.display = "block";
    const height = this.kMenuTarget.scrollHeight + "px";
    this.kMenuTarget.style.display = "";
    return height;
  }

  globalCloseKMenu(event) {
    if (
      this.kMenuTarget.contains(event.target) === false &&
      !event.target.classList.contains("js-k-menu-trigger") &&
      this.kMenuTarget.classList.contains("is-visible")
    ) {
      this.hideKMenu();
    }
  }

  openMobileMenu() {
    this.openIconTarget.classList.add("hidden");
    this.closeIconTarget.classList.remove("hidden");
    this.mobileMenuTarget.classList.remove("hidden");
  }

  closeMobileMenu() {
    this.closeIconTarget.classList.add("hidden");
    this.openIconTarget.classList.remove("hidden");
    this.mobileMenuTarget.classList.add("hidden");
  }

  globalCloseMobileMenu(event) {
    if (
      this.mobileMenuTarget.contains(event.target) === false &&
      !event.target.classList.contains("js-menu-icon") &&
      !this.mobileMenuTarget.classList.contains("hidden")
    ) {
      this.closeMobileMenu();
    }
  }
}
