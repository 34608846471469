import { tns } from "tiny-slider/src/tiny-slider";
import "tiny-slider/src/tiny-slider.scss";

import AngleLeft from "../images/angle-left.png";
import AngleRight from "../images/angle-right.png";

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider"];

  connect() {
    this.loadtns();
  }

  loadtns() {
    this.tns = tns({
      container: this.sliderTarget,
      slideBy: "page",
      controls: true,
      controlsText: [
        `<img src=${AngleLeft} alt="">`,
        `<img src=${AngleRight} alt="">`,
      ],
      nav: true,
      navPosition: "bottom",
    });
  }

  // clearCache() {
  //   document.addEventListener("turbolinks:before-cache", () => {
  //     if (this.tns != undefined) {
  //       this.tns.destroy();
  //       this.tns = null;
  //     }
  //   });
  // }

  disconnect() {
    if (this.tns) {
      this.tns.destroy();
    }
  }
}
