import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["countrySelect", "stateSelect"];

  updateStates(event) {
    Rails.ajax({
      type: "GET",
      url: "/events/update_state_select.json",
      data: "country=" + this.countrySelectTarget.value,
      success: data => {
        this.refreshDropdownValues(data);
      }
    });
  }

  refreshDropdownValues(data) {
    this.stateSelectTarget.innerHTML = "";
    if (data.length > 0) {
      this.stateSelectTarget.disabled = false;
      this.stateSelectTarget.innerHTML +=
        "<option value=''>Select state/region</option>";
    } else {
      this.stateSelectTarget.disabled = true;
    }
    for (var state of data) {
      this.stateSelectTarget.innerHTML +=
        '<option value="' + state + '">' + state + "</option>";
    }
  }
}
