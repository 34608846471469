import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["errorMessage"];

  success(e) {
    const [data, status, xhr] = e.detail;
    this.errorMessageTarget.classList.add("hidden");
    let flash_div = document.getElementsByClassName("js-flash")[0];
    flash_div.innerHTML = this.get_flash_markup();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  error(e) {
    const [data, status, xhr] = e.detail;

    this.errorMessageTarget.classList.remove("hidden");
    if (status === "Internal Server Error") {
      this.errorMessageTarget.innerHTML =
        "Sorry, something went wrong. Please try again.";
    } else {
      this.errorMessageTarget.innerHTML = data;
    }
  }

  get_flash_markup() {
    return [
      '<div class="flash" data-target="flash.container">',
      "Your password was successfully updated.",
      "</div>",
    ].join("");
  }
}
