import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "form",
    "successDiv",
    "successMessage",
    "errorDiv",
    "errorMessage",
  ];

  connect(e) {
    console.log("hello");
  }

  success(e) {
    const [data, status, xhr] = e.detail;
    this.hideErrorMessage();
    this.successDivTarget.classList.remove("hidden");
    this.successMessageTarget.innerHTML = data;
    this.formTarget.reset();
  }

  error(e) {
    const [data, status, xhr] = e.detail;
    this.hideSuccessMessage();
    this.errorDivTarget.classList.remove("hidden");
    if (status === "Internal Server Error") {
      this.errorMessageTarget.innerHTML =
        "Sorry, something went wrong. Please try again.";
    } else {
      this.errorMessageTarget.innerHTML = data;
    }
  }

  close(e) {
    e.preventDefault();
    this.hideMessages();
  }

  hideMessages() {
    this.hideSuccessMessage();
    this.hideErrorMessage();
  }

  hideSuccessMessage() {
    if (!this.successDivTarget.classList.contains("hidden")) {
      this.successDivTarget.classList.add("hidden");
      this.successMessageTarget.innerHTML = "";
    }
  }

  hideErrorMessage() {
    if (!this.errorDivTarget.classList.contains("hidden")) {
      this.errorDivTarget.classList.add("hidden");
      this.errorMessageTarget.innerHTML = "";
    }
  }

  disconnect() {
    this.hideMessages();
  }
}
