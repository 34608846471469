import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addressForm", "onlineOnly", "addressDestroy"];

  connect() {
    this.toggleForm();
  }

  toggleForm() {
    if (this.onlineOnlyTarget.checked) {
      this.addressFormTarget.classList.add("hidden");
      this.addressDestroyTarget.value = "true";
    } else {
      this.addressFormTarget.classList.remove("hidden");
      this.addressDestroyTarget.value = "false";
    }
  }
}
