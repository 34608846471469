import { Controller } from "stimulus";
import MicroModal from "micromodal";

export default class extends Controller {
  static targets = [
    "errorMessage",
    "successContainer",
    "successIcon",
    "form",
    "formContainer",
  ];
  connect() {
    MicroModal.init({
      onClose: this.close.bind(this),
    });
    this.successContainerTarget.classList.add("hidden");
  }

  success(e) {
    const [data, status, xhr] = e.detail;
    this.errorMessageTarget.classList.add("hidden");
    this.formContainerTarget.classList.add("hidden");
    this.formTarget.reset();
    this.renderSuccessIcon();
  }

  renderSuccessIcon() {
    this.successContainerTarget.classList.remove("hidden");
    this.drawPath("circle");
    this.drawPath("checkmark");
    this.successIconTarget.classList.add("bounce");
  }

  hideSuccessIcon() {
    this.successContainerTarget.classList.add("hidden");
    this.successIconTarget.classList.remove("bounce");
    this.circle.removeAttribute("style");
    this.checkmark.removeAttribute("style");
  }

  get checkmark() {
    return document.querySelector(".js-success-icon-container polyline");
  }

  get circle() {
    return document.querySelector(".js-success-icon-container circle");
  }

  drawCheckmark() {
    this.drawPath(this.checkmark);
  }

  drawCircle() {
    this.drawPath(this.circle);
  }

  //https://jakearchibald.com/2013/animated-line-drawing-svg/
  drawPath(icon) {
    const path = icon === "circle" ? this.circle : this.checkmark;
    let length = path.getTotalLength();
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 1s ease-in-out";
    path.style.strokeDashoffset = "0";
  }

  error(e) {
    const [data, status, xhr] = e.detail;

    this.errorMessageTarget.classList.remove("hidden");
    if (status === "Internal Server Error") {
      this.errorMessageTarget.innerHTML =
        "Sorry, something went wrong. We are looking into it.";
    } else {
      this.errorMessageTarget.innerHTML = data;
    }
  }

  close() {
    this.errorMessageTarget.innerHTML = "";
    this.hideSuccessIcon();
    this.errorMessageTarget.classList.add("hidden");
    this.formContainerTarget.classList.remove("hidden");
  }
}
