import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["radioWeekly", "radioMonthly", "weeknumbers"];

  connect() {
    console.log('in event frequency');
    const displayVal = this.radioMonthlyTarget.checked ? "block" : "none";
    this.weeknumbersTarget.style.display = displayVal;
  }

  radio_select(event) {
    console.log(event.currentTarget)
    if (event.currentTarget == this.radioMonthlyTarget) {
      this.weeknumbersTarget.style.display = "block";
    } else {
      this.weeknumbersTarget.style.display = "none";
      // also reset all checkboxes
    }
  }

}